import React from 'react'
import { navigate } from 'gatsby'

const defaultState = {
  lang: true,
}

const languageReducer = (state, action) => {
  const setLanguage = lang => {
    localStorage.setItem('lang', JSON.stringify(lang))
  }

  switch (action.type) {
    case 'toggle':
      setLanguage(!state.lang)
      const url = window.location.pathname.split('/')
      const strippedPath = url.slice(0, url.length - 2).join('/')
      navigate(`${strippedPath}/${state.lang === false ? '' : 'fr'}`)
      return { ...state, lang: !state.lang }
    case 'setLanguage':
      setLanguage(action.lang)
      return { ...state, lang: action.lang }
    default:
      return state
  }
}

const LanguageContext = React.createContext()

export function LangProvider({ children = null }) {
  const [languageState, dispatchLanguageState] = React.useReducer(
    languageReducer,
    defaultState
  )

  React.useEffect(() => {
    // True is english, false is french
    const currentLang = JSON.parse(localStorage.getItem('lang'))
    const splitPath = window.location.pathname.split('/')
    const isFrenchPath = splitPath.includes('fr')
    const strippedPath = splitPath
      .filter(path => path !== 'fr' && path !== '')
      .join('/')

    if (currentLang === false) {
      dispatchLanguageState({ type: 'setLanguage', lang: false })

      // Redirect
      if (!isFrenchPath) {
        navigate(`/fr/${strippedPath}`)
      }
    } else {
      dispatchLanguageState({ type: 'setLanguage', lang: true })

      // Redirect
      if (isFrenchPath) {
        navigate(`/${strippedPath}`)
      }
    }
  }, [])

  return (
    <LanguageContext.Provider value={{ languageState, dispatchLanguageState }}>
      {children}
    </LanguageContext.Provider>
  )
}

export default LanguageContext
