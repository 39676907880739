/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/components/layout.css'
import './src/components/styles/common.scss'

import React from 'react'
import { LangProvider } from './src/components/Context'
export const wrapRootElement = ({ element }) => (
  <LangProvider>{element}</LangProvider>
)
